
import { env } from "./env";
import AcademixThumb from "../src/assets/thumbnails/academix.png"
import BigAcademyThumb from "../src/assets/thumbnails/bigAcademy.jpg"

import AcademixSideMenu from "../src/assets/academix-img/logo.png"
import BigAcademySideMenu from "../src/assets/academix-img/logo_big.png"
import OfficeLogoImage from "../src/assets/images/prod-office-login-image.png"
import OfficeProdBg from "src/assets/images/office-prod-bg.png";
import DevLoginBg from 'src/assets/academix-img/login-bg.png'
import ProdHeaderLogo from 'src/assets/academix-img/logo.png'
import ProdFooterLogo from 'src/assets/academix-img/footer-logo.png'
import whiteFooterLogo from 'src/assets/academix-img/white-footer-logo.png'
import whiteHeaderLogo from 'src/assets/academix-img/white-header-logo.png'
import BigLogoImage from 'src/assets/academix-img/big-logo.png'





export const getThemeConfigData = () => {
  switch (env.environment) {
    case "dev":
      return {
        logo: BigLogoImage,
        text: "Academix",
        color: "#CE1B1B",
        textColor: "#ffffff",
        videoThumbnail: AcademixThumb,
        sideMenuLogo: AcademixSideMenu,
        loginPageBG: DevLoginBg,
        loginHeaderColor: "#000000",
        loginHeaderLogo: whiteHeaderLogo,
        loginFooterLogo: whiteFooterLogo,
        btnOutline: "danger",
        loginAbout: "The BIG Academy is a Global Higher Education Services provider based out of Dubai, UAE. We are dedicated to empowering students from diverse backgrounds to achieve their academic and career goals by providing comprehensive pathway programs that enhance their academic, language, and cultural skills."
      };

    case "office_prod":
      return {
        logo: OfficeLogoImage,
        text: "Academix",
        color: "#3567DF",
        textColor: "#3567DF",
        videoThumbnail: AcademixThumb,
        sideMenuLogo: AcademixSideMenu,
        loginPageBG: OfficeProdBg,
        loginHeaderColor: "#FFFFFF",
        loginHeaderLogo: ProdHeaderLogo,
        loginFooterLogo: ProdFooterLogo,
        btnOutline: "primary",
        loginAbout: "The Academix is a comprehensive learning management system designed to empower universities, schools, private institutions, and tuition centers"
      };

    case "big_academy":
      return {
        logo: "/images/logo/logo_academix.png",
        text: "Big Academy",
        color: "#d0312eff",
        svgIconsColor: "#CE1B1B",
        videoThumbnail: BigAcademyThumb,
        sideMenuLogo: BigAcademySideMenu

      };



      break;

    default:
      break;
  }
};





