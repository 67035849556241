import React, { Component, Suspense, createContext, useContext, useMemo } from 'react'
import {
  HashRouter, Route, Routes, BrowserRouter, useNavigate, createBrowserRouter,
  createRoutesFromElements,
  defer
} from 'react-router-dom'
import './scss/style.scss'
import { AuthLayout } from "./components/AuthLayout";
import Image from "./loading.gif";
import { LazyLoadImage } from "react-lazy-load-image-component";

const AuthContext = createContext();

const loading = (
  <div>
    <LazyLoadImage src={Image}
      width={600} height={400}
      alt="Image Alt"
    />
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const ProfilePage = React.lazy(() => import('./views/pages/profile/ProfilePage'))
const EditProfilePage = React.lazy(() => import('./views/pages/profile/EditProfilePage'))
const ChangePasswordPage = React.lazy(() => import('./views/pages/profile/ChangePasswordPage'))
const ClassRoom = React.lazy(() => import('./layout/ClassRoom'))
const HomePage = React.lazy(() => import('./views/pages/home/Home'))
const TermsPage = React.lazy(() => import('./views/pages/home/Terms'))
const PrivacyPage = React.lazy(() => import('./views/pages/home/Privacy'))
const RefundPage = React.lazy(() => import('./views/pages/home/Refund'))

const getUserData = () =>
  new Promise((resolve) =>
    setTimeout(() => {
      const user = window.localStorage.getItem("user");
      resolve(user);
    }, 1000)
  );

export const router =
  createBrowserRouter(
    createRoutesFromElements(

      <Route
        element={<AuthLayout />}
        loader={() => defer({ userPromise: getUserData() })}
      >
        <Route exact path="/" name="Login Page" element={<Login />} />
        <Route exact path="/register" name="Register Page" element={<Register />} />
        <Route exact path="/404" name="Page 404" element={<Page404 />} />
        <Route exact path="/terms-of-services" name="Terms of Service" element={<TermsPage />} />
        <Route exact path="/privacy-policy" name="Privacy Policy" element={<PrivacyPage />} />
        <Route exact path="/payment-refund-policy" name="Payment & Refund policy" element={<RefundPage />} />
        <Route exact path="/500" name="Page 500" element={<Page500 />} />
        <Route path="*" name="Class Room" element={<ClassRoom />} />
        <Route path="/profile" name="Student Profile" element={<ProfilePage />} />
        <Route path="/edit-profile" name="Student Edit Profile" element={<EditProfilePage />} />
        <Route path="/change-password" name="Student Change Password" element={<ChangePasswordPage />} />
        {/* <Route path="/" name="Home" element={<HomePage />} /> */}
      </Route>

    )
  );
